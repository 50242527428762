/* @tailwind base;
@tailwind components;
@tailwind utilities;
 
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
 
.App {
  background: #0e1217;
  font-family: "Roboto", sans-serif;
  min-height: 100vh;
  text-align: center;
  color: white;
}
 
.color-purple {
  color: #ce3df3;
} */

:root{
  --clr-primary: #10426a;
  --clr-secondary: #5adbc8;
  --clr-green: #57ddc5;
  --clr-indigo: #0d4277;
  --clr-button-fill: #6935d3;
  --clr-blue:#10617e;
  --clr-dark-blue: #12426a;
  --clr-light-blue: #e9ffff;
  --clr-gray: #e8e8e8;
  --clr-field-border: #d5dee6;
  --clr-nav-border: #ffffff;
  --clr-white: #ffffff;
  --clr-field: #f7f7f7;
  --clr-i-fill: #534;
  --clr-error: #d9534f;
}

body {
  font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
}

div#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main{
  background-color: var(--clr-nav-border);
}

/* footer {

  background-color: var(--clr-light-blue);
  margin: 0 auto;
  display: block;
  margin-top: 14vh;
  border-top: solid 2px var(--clr-blue);
  border-bottom: solid 1px var(--clr-blue);
  bottom: 0;
  width: 100%;
} */

footer p{
  text-align: center;
}